import React from "react"
import { Link } from "gatsby"
import { RiArrowLeftSLine, RiErrorWarningLine } from "react-icons/ri"

import SEO from "../components/seo"
import Layout from "../components/layout"

const NotFound = () => (
  <Layout className="not-found-page">
    <SEO title="BELMOT Oldtimerversicherung – Seite nicht gefunden" />
    <div
      className="wrapper"
      style={{
        textAlign: "center",
      }}
    >
      <header>
        <RiErrorWarningLine
          style={{
            fontSize: "128px",
            color: "var(--primary-color)",
            marginBottom: "40px",
          }}
        />
        <h1>Das hätte nicht passieren sollen...</h1>
      </header>
      <Link to="/" className="button">
        <RiArrowLeftSLine className="icon -left" />
        Zur Startseite
      </Link>
    </div>
  </Layout>
)

export default NotFound
